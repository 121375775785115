<script>
import Search from '@/pages/search/components/search'
import { GlobalSearch } from '@/pages/search/controllers'
export default {
  extends: Search,
  computed: {
    scopeModes () {
      return [GlobalSearch.LOCAL_MODE]
    }
  }
}
</script>
